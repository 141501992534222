<template>
  <div>
    <router-link :to="{ name: 'Home' }">Return Home</router-link>
    <h1>reCAPTCHA Example Here</h1>
    <p>{{ this.banner }}</p>
    <form @submit.prevent="submit" ref="accDetails">
      <p>Your username is: {{ this.username }}</p>
      <input v-model="username" placeholder="Username" />
      <p>Your password is: {{ this.password }}</p>
      <input v-model="password" placeholder="Password" />
      <p></p>
      <div id="inner">
        <vue-recaptcha
          ref="recaptcha"
          @verify="onVerify"
          size="invisible"
          :sitekey="sitekey"
        >
        </vue-recaptcha>
      </div>
      <p></p>
      <button :disabled="isDisabled" type="button" @click="submitForm">
        Login
      </button>
    </form>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";

export default {
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      banner: "Please Login",
      username: "",
      password: "",
      sitekey: process.env.VUE_APP_SITE_KEY,
    };
  },
  methods: {
    submitForm: function () {
      this.$refs.recaptcha.execute();
    },
    onVerify: function (token) {
      if (token) {
        this.robot = true;
        console.log(token);
        this.banner = "Login Successful";
        this.username = "";
        this.password = "";
      }
    },
  },
  computed: {
    isDisabled() {
      return !(this.username && this.password);
    },
  },
};
</script>

<style scoped>
#inner {
  position: fixed;
  top: 75%;
  left: 100%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
</style>