<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <img alt="Vue logo" src="../assets/logo.png" />
    <p>
      Hey there! Welcome to your Vue.js Playground at CardX. I recommend that
      you play around with your new workspace and determine the best way to add
      new features! What are you waiting for?
    </p>
    <h3>Sub-Playgrounds</h3>
    <ul>
      <li>
        <router-link :to="{ name: 'reCAPTCHA' }">reCAPTCHA</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
