import Vue from 'vue'
import VueRouter from 'vue-router'
import HelloWorld from '@/components/HelloWorld.vue'
import reCAPTCHA from '@/components/reCAPTCHA.vue'

Vue.use(VueRouter)

export default new VueRouter({
    routes: [
        {
            path: '/',
            name: 'Home',
            component: HelloWorld
        },
        {
            path: '/reCAPTCHA',
            name: 'reCAPTCHA',
            component: reCAPTCHA
        }
    ]
})